export const ADMIN_ROUTE = '/admin/*'
export const ADMIN_TYPES_CONTROLS = '/types'
export const ADMIN_MENU_ITEMS_CONTROLS = '/menu-items'
export const ADMIN_DISCOUNT_CONTROLS = '/discount'
export const ADMIN_ABOUT_US_CONTROLS = '/about-us-controls'
export const ABOUT_RESTAURANT_ROUTE = '/about-restaurant'
export const ABOUT_US_ROUTE = '/about-us'
export const BASKET_ROUTE = '/basket'
export const DELIVERY_INFO_ROUTE = '/delivery-info'
export const MENU_ROUTE = '/menu'
export const PUBLIC_OFFER_ROUTE = '/public-offer'
export const CHECKOUT_ROUTE = '/checkout'
export const SUCCESSFUL_CHECKOUT_ROUTE = '/successful-checkout'
export const ERROR_ROUTE = '/error'
export const PERSONAL_DATA_AGREEMENT_ROUTE = '/public-offer'
