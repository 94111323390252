import { FC, useState, useEffect } from "react";
import Footer from "../../components/ReusableComponents/Footer";
import CustomNavbar from "../../components/ReusableComponents/Navbar";
import SmallNavbar from "../../components/ReusableComponents/SmallNavbar";

const PublicOffer: FC = () => {
    const [dWidth, setDWidth] = useState(window.innerWidth)

    const updateDWidth = () => setDWidth(window.innerWidth)

    useEffect(() => {
        window.addEventListener('resize', updateDWidth)
        return () => {
            window.removeEventListener('resize', updateDWidth)
        }
    }, [])

    return (
        <div className="public-offer-container" style={{textAlign: 'left'}}>
            <CustomNavbar />
            {dWidth <= 470 && <SmallNavbar />}
            <h1 style={{textAlign: 'center'}}>Договор публичной оферты</h1>
            <ol>
                <li>
                    Термины, используемые в настоящем Пользовательском соглашении
                    Пользовательское соглашение (здесь и далее по тексту – Соглашение) – настоящее 
                    Соглашение, являющееся смешанным договором, определяющее порядок розничной купли-продажи товаров 
                    через интернет-магазин tobikko-sushi.ru turtles-pizza.com , условия доставки товаров, 
                    права и обязанности сторон настоящего Соглашения.Продавец, Администратор – ИП Корнилов А.Е 
                    tobikko-sushi.ru turtles-pizza.com. Пользователь, Покупатель – физическое лицо, зарегистрированное 
                    на сайте tobikko-sushi.ru turtles-pizza.com  и осуществляющее заказ доставки товаров посредством 
                    заполнения формы заказа. Интернет-магазин, Сайт – веб-сайт tobikko-sushi.ru turtles-pizza.com 
                    размещенный в телекоммуникационной сети Интернет, являющийся виртуальным каталогом Продавца, 
                    содержащий описание, фотографические изображения, стоимость и иную необходимую информацию о 
                    продаваемых товарах Продавца. Товар – блюда, изготавливаемые Продавцом по заказу Покупателя. 
                    Заказ – перечень Товаров, объединенных Покупателем в целях их приобретения. 
                    Регистрация на сайте – заполнение Пользователем контактной информации, достаточной для определения 
                    условий выполнения заказа (фамилия и имя, контактный мобильный телефон, электронная почта, 
                    адрес доставки). Зарегистрировавшись на сайте, Покупатель получает индивидуальную идентификацию 
                    путем предоставления логина и пароля. Личный кабинет Пользователя – закрытый от общего доступа 
                    раздел Интернет-магазина, содержащий информацию о Покупателе, включая адрес доставки Заказа, 
                    контактный мобильный телефон, электронную почту фамилию и имя.</li>
                <li>
                    Общие положения
                    <ol>
                        <li> К отношениям между Покупателем и Продавцом применяются положения Гражданского Кодекса РФ 
                        (в том числе положения о розничной купле-продаже (§ 2, глава 30), Закон РФ 
                        «О защите прав потребителей» от 07.02.1992 № 2300-1, Правила продажи товаров дистанционным способом, 
                        утверждённые Постановлением Правительства РФ № 612 от 27.09.2007 года и иные правовые акты, 
                        принятые в соответствии с ними.</li>
                        <li> Заказывая доставку Товара через Интернет-магазин посредством оформления Заказа, 
                        Покупатель осуществляет полный и безоговорочный акцепт условиям договора розничной купли-продажи, 
                        изложенным в настоящем Соглашении.</li>
                        <li> Покупатель гарантирует, что все условия настоящего Соглашения ему понятны, и он принимает их 
                        безусловно и в полном объёме. Покупатель гарантирует, что он обладает необходимой право- и 
                        дееспособностью, а равно всеми правами и полномочиями, необходимыми и достаточными для заключения 
                        и исполнения договора розничной купли-продажи.</li>
                        <li> Возможность заказа Товара предоставлена только для зарегистрированных пользователей. 
                        Всю ответственность за правильность введенных данных несет Пользователь.</li>
                    </ol>
                </li>
                <li>
                    Права и обязанности Продавца
                    <ol>
                    <li> Продавец обязан осуществить доставку Товара Покупателю в соответствии с условиями Заказа.
                    </li>
                    <li> Продавец обязан приступить к выполнению Заказа непосредственно после его получения от Покупателя.
                    </li>
                    <li> Продавец обязан исполнить Заказ в полном соответствии с ассортиментом и количеством Товара.
                    </li>
                    <li> Продавец обязан обеспечить сохранность персональных данных Покупателя.
                    </li>
                    <li> Продавец имеет право заблокировать учетную запись Пользователя, если она содержит явно 
                    недостоверную информацию о себе, в том числе, но не исключительно, несуществующий адрес, 
                    неправильный формат телефона, явно вымышленное имя.
                    </li>
                    <li> Продавец вправе направлять Покупателю электронные сообщения рекламно-информационного характера. 
                    Если Покупатель не желает получать рассылки от Продавца, он должен отказаться от получения такой 
                    рассылки в Интернет-магазине путем направления электронного сообщения в адрес Продавца!
                    </li>
                    </ol>
                </li>
                <li>
                    Права и обязанности Покупателя<br/>
                    4.1. Покупатель обязан соблюдать условия настоящего Соглашения.<br/>
                    4.2. Покупатель обязан предоставить достоверную информацию о себе в пределах запрашиваемой 
                    Продавцом информации, необходимой для регистрации на Сайте.<br/>
                    4.3. Покупатель обязан самостоятельно ознакомиться с предоставляемой Продавцом на Сайте информацией 
                    о цене, об условиях приобретения Товара, о потребительских свойствах Товара, об условиях его 
                    хранения, об условиях его доставки (получения), о порядке оплаты Товара.<br/>
                    4.4. Покупатель обязан самостоятельно ознакомиться с адресом места нахождения Продавца, 
                    его полным фирменным наименованием, иными реквизитами.<br/>
                    4.5. Покупатель обязуется своевременно и полностью оплачивать приобретаемый Товар в порядке и в 
                    сроки, установленные в настоящем Соглашении.<br/>
                    4.6. В случае обнаружении брака, порчи или несоответствия доставленных позиций Товара заказу, 
                    Покупатель обязуется извещать Продавца в момент Передачи Товара Покупателю по телефону.<br/>
                    4.7. Покупатель обязуется ни при каких условиях не передавать третьим лицам свой логин и пароль 
                    для доступа к личному кабинету Пользователя. Покупатель самостоятельно несёт ответственность за 
                    все возможные негативные последствия, в случае передачи логина и пароля третьим лицам. 
                    В случае возникновения у Покупателя подозрений относительно безопасности его логина и пароля 
                    или возможности их несанкционированного использования третьими лицами, Покупатель незамедлительно 
                    уведомляет об этом Продавца, направив электронное сообщение.<br/>
                    4.8. Покупатель может изменить или отменить заказ не позднее 15 минут, 
                    после его отправки Продавцу через форму Интернет-магазина. Изменить или отменить заказ 
                    Покупатель может только посредством телефонного звонка Продавцу.<br/>
                    4.9. При получении Товара в ассортименте, полностью или частично не соответствующем Заявке, 
                    Покупатель вправе потребовать его замены Товаром, соответствующим условиям Заявки или отказаться 
                    от Товара.<br/>
                    4.10. Покупатель может оставлять свои отзывы относительно работы Интернет-магазина или службы 
                    доставки в специальном разделе Сайта (Отзывы).
                </li>
                <li>
                    Стоимость товара, порядок доставки и оплаты Заказа<br/>
                    5.1. Стоимость Товара указывается в строке с определенным наименованием Товара в Интернет-магазине 
                    в российских рублях. Стоимость Товара может меняться по усмотрению Продавца. В случае расхождения 
                    стоимости товара, указанной в Интернет-магазине, со стоимостью Товара, указанной в Заказе, 
                    стоимость определяется по цене, указанной в направленном Продавцу Заказе.<br/>
                    5.2. Товар доставляется Покупателю по адресу, указанному в Заказе, в пределах зоны доставки.
                    <br/>
                    5.3. Товар доставляется автотранспортом в специальных контейнерах.<br/>
                    5.4. Время доставки – в течение 1-2 часов с момента направления Заказа, либо в иное время, 
                    указанное в Заказе Покупателем. Время доставки может быть увеличено при наличии большого объема 
                    заказов, но не более чем на три часа.<br/>
                    5.5. Покупатель оплачивает стоимость Заказа непосредственно при его получении наличными денежными 
                    средствам в российских рублях или по безналичному расчету. Так же возможна оплата посредством 
                    Мобильного банка.
                </li>
                <li>
                    Ответственность сторон<br/>
                    6.1. За неисполнение или ненадлежащее исполнение своих обязательств по настоящему Договору стороны 
                    несут ответственность в соответствии с настоящим Соглашением и законодательством Российской Федерации.
                    <br/>
                    6.2. Продавец не несет ответственности за ущерб, причиненный Покупателю вследствие ненадлежащего 
                    использования Товаров, приобретённых в Интернет-магазине, а также при нарушении условий хранения Товара.
                    <br/>
                    6.3. Продавец не несет ответственности за своевременность доставки Товара Покупателю в случае 
                    неправильного заполнения Заказа, в том числе неправильного указания контактного телефона, адреса 
                    доставки или иных данных, необходимых для своевременной доставки Товара, а также в результате 
                    неправомерных действий третьих лиц.<br/>
                    6.4. Стороны освобождаются от ответственности за частичное или полное неисполнение обязательств по 
                    настоящему Договору, если это неисполнение явилось следствием обстоятельств непреодолимой силы, 
                    возникших после заключения договора в результате событий чрезвычайного характера, которые 
                    Сторона не могла ни предвидеть, ни предотвратить разумными мерами.<br/>
                    6.5. К обстоятельствам непреодолимой силы, в частности, относятся: пожары, землетрясения, наводнения 
                    и другие природные стихийные бедствия, объявленная или фактическая война, гражданские волнения, 
                    эпидемии, блокада, эмбарго, общегосударственный кризис, забастовки в отрасли или регионе, 
                    действия и решения государственных органов власти, сбои, возникающие в телекоммуникационных и 
                    энергетических сетях, действие вредоносных программ, а также недобросовестные действия третьих лиц, 
                    выразившиеся в действиях, направленных на несанкционированный доступ и/или выведение из строя 
                    программного и/или аппаратного комплекса каждой из Сторон.
                </li>
                <li>
                    Конфиденциальность и защита персональных данных<br/>
                    7.1. Продавец использует полученную от Покупателя информацию исключительно для: регистрации Покупателя в Интернет-магазине; для выполнения своих обязательств перед Покупателем в соответствии с настоящим Соглашением; для оценки и анализа работы Интернет-магазина.
                    <br/>
                    7.2. Разглашение информации, полученной Продавцом:<br/>
                    7.2.1. Продавец обязуется не разглашать полученную от Покупателя информацию. Не считается нарушением предоставление Продавцом информации агентам и третьим лицам, действующим на основании договора с Продавцом, для исполнения обязательств перед Покупателем.
                    <br/>
                    7.2.2. Не считается нарушением обязательств разглашение информации в соответствии с обоснованными и применимыми требованиями закона, либо по запросу уполномоченного органа государственной власти.
                    <br/>7.3. Продавец вправе использовать технологию cookies (cookies — служебная информация, посылаемая веб-сервером на компьютер пользователя, для сохранения в браузере. Применяется для сохранения данных, специфичных для данного пользователя, и используемых веб-сервером для различных целей). Cookies не содержат конфиденциальную информацию и не передаются третьим лицам.
                    <br/>7.4. Продавец не несет ответственности за сведения, предоставленные Покупателем на Сайте в общедоступной форме.
                </li>
                <li>
                    Заключительные положения<br/>
                    8.1. В отношении формы заключения настоящего Соглашения применяются нормы Гражданского 
                    кодекса РФ, регулирующие порядок и условия заключения публичного договора (ст. 426 ГК РФ), 
                    договора присоединения (ст. 428 ГК РФ), оферты (ст. 435-444 ГК РФ, договора розничной 
                    купли-продажи (§2 гл. 30 ГК РФ).<br/>
                    8.2. В соответствии с настоящим Соглашение стороны признают 
                    юридическую силу текстов документов, исполненных в электронном виде, полученных по 
                    электронной почте, наравне с документами, исполненными в простой письменной форме.<br/>
                    8.3. Стороны примут все меры к разрешению споров и разногласий, которые могут возникнуть 
                    при исполнении обязательств по Договору или в связи с этим, путем переговоров.<br/>
                    8.4. В случае если Стороны не пришли к соглашению, все споры подлежат рассмотрению в 
                    соответствии с процедурой, предусмотренной законодательством РФ.<br/>
                    8.5. Признание судом какого-либо положения настоящего Соглашения недействительным или 
                    не подлежащим принудительному исполнению не влечет недействительности или неисполнимости 
                    иных положений Соглашения.<br/>
                    8.6. Настоящее Соглашение может изменяться или дополняться Администратором в одностороннем 
                    порядке. Актуальная версия Соглашения. К отношениям, возникающим в момент осуществления 
                    Заказа, применяются положения актуальной версии Соглашения.<br/>
                    8.7. Во всем остальном, не предусмотренном настоящим Соглашением, Стороны руководствуются 
                    действующим законодательством РФ.
                </li>
                <li>Информация на сайте не является публичной офертой ст 437 ГК РФ</li>
                <li>Внешний вид товаров может отличаться от представенных на фотографиях в каталоге.</li>
            </ol>
            <Footer />
        </div>
    )
}

export default PublicOffer