import { FC } from "react";

const PersonalDataAgreement: FC = () => {
    return (
        <div className="personal-data-agreement-container">
            Personal data agreement
        </div>
    )
}

export default PersonalDataAgreement